<template>
  <div
      v-if="renderComponent"
      :key="'btnLoading-key-btn' + componentKey"
  >
    <b-row no-gutters class="playground-p-title ml-5">
      {{ $t('views.playground.steps-descriptions.step5.title') }}
    </b-row>
    <b-row no-gutters class="playground-p-description ml-5">
      <b-col cols="10">
        {{ $t('views.playground.steps-descriptions.step5.content') }}
      </b-col>
      <b-col>
        <d-button
            :text="'general.actions.save'"
            :icon-position="'right'"
            class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title"
            @on:button-click="onSave"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    name: "description",
    data: () => ({
      renderComponent: true,
      componentKey: 1,
      btnLoading: false,
      enabled: false,
    }),
    props: {
      selectedBlocks: {
        type: Array,
        default: this
      },
    },
    computed: {
      loading : {
          get() {
            return this.btnLoading;
          },
          set(loader) {
            this.btnLoading = loader;
          },
        },
    },
    methods: {
      onSave () {
         this.$emit('on:step-4-save');
      },
    },
  }
</script>
<style scoped>
.playground-p-title {
  padding-top: 0px;
  padding-bottom: 15px;
  text-align: left;
  font: normal normal 28px/27px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

.playground-p-description {
  text-align: left;
  font: normal normal 18px/27px Avenir;
  letter-spacing: 0px;
  color: #4D4F5CBC;
  opacity: 1;
}
</style>